.landing-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  font-family: 'Rubik', sans-serif;
  color: white;
}

.landing-page > h1, h2 {
  margin: 0;
}

.landing-page > h1 {
  font-size: 10rem;
  padding: 2rem 10rem 0 10rem;
  color: rgb(245, 0, 106);
  text-align: center;
}

.landing-page > h2 {
  font-size: 6rem;
  padding: 0rem 10rem 5rem 10rem;
  opacity: 0.8;
}

.bounce-in-top {
	animation: bounce-in-top 1.1s both;
}

 @keyframes bounce-in-top {
  0% {
    transform: translateY(-500px);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    transform: translateY(0);
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    transform: translateY(-65px);
    animation-timing-function: ease-in;
  }
  72% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }
  81% {
    transform: translateY(-28px);
    animation-timing-function: ease-in;
  }
  90% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }
  95% {
    transform: translateY(-8px);
    animation-timing-function: ease-in;
  }
  100% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }
}

.scallop {
  position:relative;
  background: rgba(234 190 103);
  padding:0px;
}

.scallop::after {
  position:absolute;
  bottom: -50px;
  left: 0px;
  width: 100%;
  content:" ";
  background:
  radial-gradient(circle at 50% 0%, rgba(234 190 103) 25%, white 26%, white 40%);
  background-color: white;
  background-size:50px 100px;
  height:50px;
  background-repeat: repeat-x;

  /* background-color: rgba(234 190 103); */

}
.after-scallop{
  background: white;
  padding:0px;
  /* margin-bottom:50px; */
}

@media (min-width: 375px) and (max-width: 900px) {

  .landing-page {
    justify-content: center;
    text-align: center;
  }

  .landing-page > h1 {
    font-size: 3rem;
    padding: 3rem 2rem 0 2rem;
  }

  .landing-page > h2 {
    font-size: 2rem;
    padding: 1rem 2rem 5rem 2rem;
  }
}

/* smaller phones e.g. iPhone mini / SE */
@media (min-width: 100px) and (max-width: 375px) {

  .landing-page {
    justify-content: center;
    text-align: center;
  }

  .landing-page > h1 {
    font-size: 3rem;
    padding: 3rem 2rem 0 2rem;
  }

  .landing-page > h2 {
    font-size: 2rem;
    padding: 1rem 2rem 5rem 2rem;
  }
}
