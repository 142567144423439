.project {
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  font-family: 'Rubik', sans-serif;
  color: white;
}

.project--gif {
  border-radius: 0.625em;
  width: 50rem;
  padding: 5rem 3rem 0 10rem;
  margin: 0;
}

.project--info {
  padding: 5rem 10rem 0 0rem;
  margin: 0;
}

.project--title {
  font-size: 3rem;
  padding: 0;
  margin: 0;
  color: rgb(245, 0, 106);
  /* text-decoration: underline; */
  font-weight: bold;
}

.gif {
  border-radius: 0.5rem;
  height: auto;
}

.project--description {
  font-size: 1rem;
  padding: 1rem 0 0 0;
  margin: 0;
  line-height: 2rem;
  opacity: 0.8;
  text-align: justify;
  font-weight: bolder;
}

.project--display {
  display: flex;
  flex-direction: column;
}

.project--icons {
  display: flex;
  flex-direction: row;
  padding: 0.5rem 3rem 0 10rem;
  margin: 0;
}

.tech-icon {
  color: white;
  font-size: 1rem;
  margin: 0 5px 0 0;
  padding: 0.3rem;
  background-color: rgb(245, 0, 106);
  opacity: 0.6;
  border-radius: 0.5rem;
}

.link-icon {
  background-color: white;
  font-size: 1rem;
  margin: 0 5px 0 0;
  padding: 0.4rem 0.3rem 0.3rem 0.4rem;
  color: rgb(245, 0, 106);
  opacity: 0.6;
  border-radius: 0.5rem;
}

.link-icon:hover {
  opacity: 1;
  text-decoration: none;
}


.github-icon {
  background-color: white;
  font-size: 1rem;
  margin: 0 5px 0 0;
  padding: 0.4rem 0.3rem 0.3rem 0.4rem;
  color: rgb(245, 0, 106);
  opacity: 0.6;
  border-radius: 0.5rem;
}

.github-icon:hover {
  opacity: 1;
}

a {
  text-decoration: none;
  text-align: center;
}

a:hover {
  text-decoration: underline;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-button {
  display: inline-block;
  padding: 10px 20px;
  margin: 3rem 0 3rem 0;
  font-size: 1rem;
  border: none;
  background-color: rgb(245, 0, 106);
  border-radius: 20px; /* Adjust the radius for the scalloped effect */
  position: relative;
  overflow: hidden;
}

.project-button p {
  color: white;
  font-family: 'Rubik', sans-serif;
  font-size: 1rem;
  font-weight: bolder;
}

.project-button:hover {
  opacity: 0.7;
}

@media (min-width: 100px) and (max-width: 900px) {

  .project {
    flex-direction: column-reverse;
    align-content: center;
  }

  .project--description {
    font-size: 1rem;
    line-height: 1rem;
    font-weight: 500;
    text-align: center;
  }

  .project--info {
    padding: 1rem 4rem 0rem 4rem;
    align-items: center;
    line-height: 2rem;
  }

  .gif {
    width: auto;
  }

  .project--gif {
    width: 15rem;
    border-radius: 10px;
    padding: 1rem 1rem 0 1rem;
  }

  .project--display {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .project--title {
    font-size: 2rem;
    padding: 2rem 0 0 0;
    margin: 0;
    color: rgb(245, 0, 106);
    text-align: center;
    font-weight: bolder;
  }

  .project--icons {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0.5rem 0rem 0 0rem;
    margin: 0;
  }

  .tech-icon {
    /* display: none; */
    color: white;
    font-size: 0.5rem;
    margin: 0 3px 0 0;
    padding: 0.2rem;
    background-color: rgb(245, 0, 106);
    opacity: 0.6;
    border-radius: 0.2rem;
  }

  .link-icon {
    background-color: white;
    font-size: 0.5rem;
    margin: 0 3px 0 0;
    padding: 0.2rem;
    color: rgb(245, 0, 106);
    opacity: 0.6;
    border-radius: 0.2rem;
  }

  .github-icon {
    /* display: none; */
    background-color: white;
    font-size: 0.5rem;
    margin: 0 0 0 0;
    padding: 0.2rem;
    color: rgb(245, 0, 106);
    opacity: 0.6;
    border-radius: 0.2rem;
  }

}

/* smaller phones e.g. iPhone mini / SE */
@media (min-width: 100px) and (max-width: 375px) {

  .project--description {
    font-size: 1rem;
    line-height: 1rem;
    font-weight: 500;
    text-align: center;
  }

  .project--info {
    padding: 1rem 4rem 0rem 4rem;
    align-items: center;
    line-height: 2rem;
  }

  .project--gif {
    border-radius: 0.5rem;
    width: 19rem;
  }
}
