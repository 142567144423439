.footer {
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: whitesmoke;
  opacity: 0.8;
}

.react-icon {
  font-size: 0.8rem;
  padding: 0;
  margin: 0;
}

.footer-content {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.footer-text {
  text-align: center;
  font-size: 1rem;
  padding: 0.5rem 0 0.5rem 0;
  color: rgb(245, 0, 106);
  margin: 0;
}

@media (min-width: 100px) and (max-width: 900px) {
 .footer-text {
  font-size: 0.5rem;
 }

 .react-icon {
  font-size: 0.3rem;
  padding: 0;
  margin: 0;
}
}
