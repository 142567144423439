.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0;
  margin: 0;
  font-family: 'Rubik', sans-serif;
}

.header > a, p {
  text-decoration: none;
  color: white;
  margin: 0;
  font-size: 2rem;
}

.header > a {
  padding: 1rem 2rem 0 0;
}


.linkedIn-icon {
  padding: 0 2rem 0 0;
}

.header > p:hover {
  text-decoration: underline;
}

.header > a:hover {
  color: rgb(245, 0, 106);
  text-decoration: underline;
}

.slide-left {
	-webkit-animation: slide-left 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-left 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
  }
}
@keyframes slide-left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
  }
}

@media (min-width: 100px) and (max-width: 768px) {
  .header > a, p {
    font-size: 1rem;
    padding: 3%;
  }

  .header {
    justify-content: center;
    align-items: center;
  }

  .linkedIn-icon {
    padding: 0;
  }

  .slide-left {
    -webkit-animation: slide-left 0s cubic-bezier(0.250, 0.460, 0.450, 0.940);
            animation: slide-left 0s cubic-bezier(0.250, 0.460, 0.450, 0.940);
  }
}

@media (min-width: 768px) {
  .header > a, p {
    font-size: 2rem;
    padding: 3%;
  }

  .header {
    justify-content: center;
    align-items: center;
  }

  .linkedIn-icon {
    padding: 0;
  }

  .slide-left {
    -webkit-animation: slide-left 0s cubic-bezier(0.250, 0.460, 0.450, 0.940);
            animation: slide-left 0s cubic-bezier(0.250, 0.460, 0.450, 0.940);
  }
}

/* 320px — 480px: Mobile devices
481px — 768px: iPads, Tablets
769px — 1024px: Small screens, laptops
1025px — 1200px: Desktops, large screens
1201px and more —  Extra large screens, TV */
