.about-me {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  padding: 0;
  margin: 0;
  font-family: 'Rubik', sans-serif;
  color: black;
  background-color: white;
}

.about-me > h1 {
  font-size: 4rem;
  color: rgb(245, 0, 106);
  padding: 5rem 10rem 0 10rem;
  margin: 0;
  /* opacity: 0.6; */
}

.about-me > p {
  font-size: 2rem;
  margin: 0;
  padding: 2rem 10rem 5rem 10rem;
  opacity: 0.8;
  line-height: 3rem;
  color: black;
}

.about-me--contact p {
  font-size: 2rem;
  margin: 0;
  padding: 2rem 20rem 5rem 10rem;
  opacity: 0.8;
  line-height: 3rem;
  color: black;
  text-align: left;
  padding: 0;
}

.about-me--tech-stack {
  display: flex;
  flex: row;
  align-items: center;
  justify-content: center;
  color: rgb(245, 0, 106);
  font-size: 4rem;
  margin: 0 1rem 3rem 0;
}
.about-me--tech-stack .icon {
  margin: 0 1rem 3rem 1rem;
}

.emphasis {
  color: rgb(245, 0, 106);
  text-decoration: underline;
  opacity: 1;
}

.scallop-white {
  position:relative;
  background: white;
  padding:0px;
}

.scallop-white::after {
  position:absolute;
  bottom: -50px;
  left: 0px;
  width: 100%;
  content:" ";
  background:
  radial-gradient(circle at 50% 0%, white 25%, rgba(234 190 103)  26%, rgba(234 190 103)  40%);
  background-color: rgba(234 190 103) ;
  background-size:50px 100px;
  height:50px;
  background-repeat: repeat-x;
}

.after-scallop-white{
  background: rgba(234 190 103) ;
  padding:0px;
}

@media (min-width: 100px) and (max-width: 768px) {
  .about-me > h1 {
    font-size: 2rem;
    padding: 3rem 1rem 0 1rem;
    text-align: center;
  }

  .about-me > p {
    font-size: 1rem;
    padding: 3%;
    line-height: 1rem;
    padding: 1rem 4rem 3rem 4rem;
    text-align: center;
    font-weight: 500;
  }
}

/* smaller phones e.g. iPhone mini / SE */
@media (min-width: 100px) and (max-width: 900px) {

  .about-me > h1 {
    font-size: 2rem;
    padding: 3rem 1rem 0 1rem;
    text-align: center;
  }

  .about-me > p {
    font-size: 1rem;
    padding: 3%;
    line-height: 1rem;
    padding: 1rem 4rem 2rem 4rem;
    text-align: center;
    font-weight: 500;
  }
}
