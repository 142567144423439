/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;400;700&family=Roboto&family=Rubik:wght@300;600;800;900&family=Source+Serif+4:opsz,wght@8..60,200;8..60,400&display=swap');

/* Components */
@import './styling/header.css';
@import './styling/landing-page.css';
@import './styling/about-me.css';
@import './styling/project.css';
@import './styling/contact.css';
@import './styling/footer.css';
@import './styling/project-thumbnail.css';


.App {
  width: 100%;
  padding: 0;
  margin: 0;
  font-family: 'Rubik', sans-serif;
  color: white;
  scroll-behavior: smooth;
}

.line {
  color: white;
  box-shadow: none;
  border: .1rem solid white;
  width: 90%;
  text-align: center;
  margin: auto;
}

.section-header {
  padding: 4rem 0 0 0;
  margin: 0;
  font-size: 4rem;
  text-align: center;
}

.thumbnails {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

@media (min-width: 100px) and (max-width: 768px) {
  .section-header {
    padding: 4rem 0 0 0;
    font-size: 2rem;
  }
}

/* smaller phones e.g. iPhone mini / SE */
@media (min-width: 100px) and (max-width: 375px) {
  .section-header {
    padding: 4rem 0 0 0;
    font-size: 3rem;
  }

}





















/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
